<template>

    <div class="event-section section event-tips" v-if="dataSet.data !== null">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-lg-3 col-xl-4">
                    <div class="section-head mt-lg-40">
                        <h3 class="section-head__title">Veranstaltungstipps</h3>
                        <h4 class="section-head__info">Aktuelle Veranstaltungen und Angebote</h4>
                        <a :href="linkTo" title="Mehr Veranstaltungstipps anzeigen" class="btn mt20 d-none d-lg-block">MEHR ANZEIGEN</a>
                    </div>
                </div>

                <div class="col-12 col-lg-9 col-xl-8">
                    <div class="row mobile--scrolling dropShadowWorkaround">
                        <div class="event-card d-flex align-items-stretch" v-for="(item, index) in dataSet.data" :key="index">
                            <angebot-card :item="item" :noslider="true"/>
                        </div>
                    </div>
                </div>
            </div>
            <a :href="linkTo" title="Mehr Veranstaltungstipps anzeigen" class="btn btn-block mt30 d-lg-none">MEHR ANZEIGEN</a>
        </div>
    </div>

</template>

<script>
    import Resource from '@/api/resource';
    import { getContentsOfType } from '@/api/content';
    const contentTypeResource = new Resource('contenttypes');
    import { showLoader, hideLoader, getStructuredFilters, getBasicFilters, isset } from '@/utils/helpers';

    export default {
        name: "eventSection",
        components: {
            AngebotCard: () => import('@/components/cards/Angebot.vue'),
        },
        props: {
            category: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                contentType: null,
                dataSet: {
                  total: 0,
                  data: null,
                  meta:null,
                  query: {
                    page:1,
                    limit: 3,
                    selectRandomly: true,
                    selectedContentTypeId: this.$offerId,
                    view_status: 1,
                    sort: {
                      prop: '',
                      order: 'asc'
                  },
              },
          },
      }
  },
  created(){
    this.getContentType();
    this.getContents();
}, 
computed:{
    linkTo(){
        if(isset(this.mainCategory)){
            const args = new Map();
            args.set(this.mainCategory.id,[this.mainCategory.value]);
            return "/angebote?filters="+encodeURIComponent(JSON.stringify(Array.from(args.entries())));
        }
        return "/";
    },
    categoryId(){
        if(this.structuredFilters!==null){
            var kategorieFilter = this.structuredFilters[0];
            if(kategorieFilter!==undefined){
                return kategorieFilter.field_type_id;
            }
        }
        return null;
    },
    structuredFilters(){
       return getStructuredFilters(this.contentType);
   },
   categoryFilters(){
    if(this.structuredFilters!==null){
        var kategorieFilter = this.structuredFilters[0];
        if(kategorieFilter!==undefined){
            for(var i = 0; i<kategorieFilter.options.length;i++){
                if(kategorieFilter.options[i].label == this.category){
                    return kategorieFilter.options[i];
                }
            }
        }
    }
    return null;
},
mainCategory(){
    if(this.categoryFilters!==null){
        return {
            id: this.categoryId,
            value : this.categoryFilters.label
        }
    }
    return null;
},
},

methods: {
   showLoader, 
   hideLoader,
   getContents() {

    if(this.mainCategory!==null){
        const args = new Map();
        args.set(this.mainCategory.id,this.mainCategory.value);
        this.dataSet.query.filters = JSON.stringify(Array.from(args.entries()));
    }

    this.dataSet.query.type="view";
    const { limit, page } = this.dataSet.query;
    getContentsOfType(this.$offerId,this.dataSet.query).then(response => {
        const { data, meta } = response;
        this.dataSet.data = data;
        this.dataSet.data.forEach((element, index) => {
          element['index'] = (page - 1) * limit + index + 1;
      });
        this.dataSet.meta = meta;
        this.dataSet.total = meta.total;
        this.tempContentCount = meta.total;
    });
},
getContentType() {
    this.contentType = null;
    var id = this.$offerId;
    /*overwrite the existing filters*/
    this.selectedFilters = new Map();
    contentTypeResource.get(id).then(response => {
        this.contentType = response.data;
    })
}
}
}
</script>
<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .event-section {


        .event-card{
            width: 325px;
            min-width: 325px;
            margin-left: 15px;

            &:last-child{
                margin-right:15px;
            }
        }

        a.btn{
            color: #fff;
            max-width: 210px; 

            &:hover{
                color: #C8D400;
                text-decoration: none;
            }
        }

    }

    .dropShadowWorkaround {
        padding-bottom: 10px;
    }

    body[dir="rtl"] {
        .event-section{

            .event-card{

                &:first-child{
                    padding-right: 15px;
                }

                &:last-child{
                    margin-right: 0px;
                }
            }
        }
    }

</style>
